import styled from "styled-components"

const Email = styled.a`
  margin-top: 8px;
  display: inline-block;
  color: ${props => props.theme.colors.main};
  font-size: 20px;

  &:hover {
    color: ${props => props.theme.colors.hover};
  }
`

export default Email
