import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
// import ButtonWrapper from "./ButtonWrapper"
// import Button from "../../shared-styled-components/Button"
import Title from "../../shared-styled-components/Title"
import Text from "../../shared-styled-components/Text"
import Email from "./Email"
import Phone from "./Phone"
import NormalText from "./NormalText"

import ItalicText from "./ItalicText"

const Contact = () => {
  return (
    <Wrapper>
      <Title>Kontakt</Title>
      <Text>
        <ItalicText>
          Momentálně jsem otevřený novým spoluprácím, ale na větší projekty budu
          mít kapacitu až od září 2023.
        </ItalicText>
      </Text>
      <Text>
        Kontaktujte mě v případě, že se mnou budete chtít probrat svůj další
        projekt. Společně vymyslíme nejlepší řešení. :) Napište mi i v případě,
        že na mě máte jakýkoliv jiný dotaz.
      </Text>
      <Text>
        Ing. Karel Kvítek<br></br>
        <NormalText>
          IČ: 04322789<br></br>
          DIČ: CZ9107136093<br></br>
        </NormalText>
        <Email href="mailto:karel.kvitek@seznam.cz" aria-label="E-mail">
          karel.kvitek@seznam.cz
        </Email>
        <br></br>
        <Phone href="tel:+420775977237" aria-label="Telefon">
          +420 775 977 237
        </Phone>
        <br></br>
      </Text>
      {/* <ButtonWrapper>
        <Button>Kontaktovat</Button>
      </ButtonWrapper> */}
    </Wrapper>
  )
}

export default Contact
