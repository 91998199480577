import React from "react"

//React components imports
import Layout from "../components/Layout"
import Contact from "../components/Contact"

const ContactPage = () => {
  return (
    <Layout location="contact">
      <Contact />
    </Layout>
  )
}

export default ContactPage
